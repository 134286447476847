<template>
  <div class="bootstrap snippets bootdeys">
    <div class="row">
      <div class="col-sm-12">
        <div class="panel panel-default invoice" id="invoice">
          <div v-if="isLoading">
            Loading order details...
          </div>
          <div class="panel-body" v-else>
            <div class="invoice-ribbon">
              <div class="ribbon-inner">{{ order.status.label }}</div>
            </div>
            <div class="row">
              <div class="col-sm-6 top-left">
              </div>
              <div class="col-sm-6 top-right">
                <h3 class="marginright">INVOICE {{ order.order_number }}</h3>
                <span class="marginright">{{ order.created_at | formatDate }}</span>
              </div>
            </div>
            <hr>
            <div class="row">

              <div class="col-4 col-xs-4 from">
                <p class="lead marginbottom">From : {{ order.user.name }}</p>
                <template v-if="order.billingAddress">
                  <p>{{ order.billingAddress.name }}</p>
                  <p>{{ order.billingAddress.street }}</p>
                  <p>{{ order.billingAddress.city }}</p>
                  <p>{{ order.billingAddress.province }}</p>
                  <p>{{ order.billingAddress.country }}</p>
                  <p>Phone: {{ order.billingAddress.phone_number }}</p>
                  <p>Email: {{ order.billingAddress.email }}</p>
                </template>
              </div>

              <div class="col-4 col-xs-4 to" v-if="order.shippingAddress">
                <p class="lead marginbottom">To : {{ order.shippingAddress.contact_name }}</p>
                <p>{{ order.shippingAddress.name }}</p>
                <p>{{ order.shippingAddress.street }}</p>
                <p>{{ order.shippingAddress.city }}</p>
                <p>{{ order.shippingAddress.province }}</p>
                <p>{{ order.shippingAddress.country }}</p>
                <p>Phone: {{ order.shippingAddress.phone_number }}</p>
                <p>Email: {{ order.shippingAddress.email }}</p>
              </div>
              <div class="col-4 col-xs-4 to" v-else>
                <p class="lead marginbottom">To : -</p>
              </div>

              <div class="col-4 col-xs-4 text-right payment-details">
                <p class="lead marginbottom payment-info">Payment details</p>
                <p v-if="isPaid">Date: {{ order.paid_at | formatDate }}</p>
                <p>Total Amount: {{ order.total_amount | toCurrency }}</p>
              </div>

            </div>

            <div class="row table-row">
              <table class="table table-striped table-bordered">
                <thead>
                <tr>
                  <th class="text-center" style="width:5%" v-if="order.type.name === 'product'">#</th>
                  <th style="width:50%">Item</th>
                  <th class="text-right" style="width:15%">Quantity</th>
                  <th class="text-right" style="width:15%">Unit Price</th>
                  <th class="text-right" style="width:15%">Total Price</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="orderItem in order.order_items" :key="orderItem.id">
                  <td class="text-center" v-if="order.type.name === 'product'">
                    <span v-if="order.type.name === 'product'">{{ orderItem.product.code }}</span>
                  </td>
                  <td>
                    <span v-if="order.type.name === 'prescription'">{{ orderItem.prescription.code }}</span>
                    <span v-if="order.type.name === 'product'">{{ orderItem.product.name }}</span>
                  </td>
                  <td class="text-right">{{ orderItem.quantity }}</td>
                  <td class="text-right">{{ orderItem.price | toCurrency }}</td>
                  <td class="text-right">{{ orderItem.total | toCurrency }}</td>
                </tr>
                </tbody>
              </table>
            </div>

            <div class="row">
              <div class="col-6 col-xs-6 margintop">
              </div>
              <div class="col-6 col-xs-6 text-right pull-right invoice-total">
                <!--                <p>Subtotal : {{ order.sub_total | toCurrency }}</p>-->
                <p>Total : {{ order.total_amount | toCurrency }} </p>
              </div>
            </div>

            <div class="row">
              <div class="col-6 col-xs-6 margintop">
                <p class="lead marginbottom">THANK YOU!</p>
                <button class="btn btn-success" id="invoice-print"><i class="fa fa-print"></i> Print Invoice</button>
                &nbsp;&nbsp;
                <button class="btn btn-danger" @click="sendEmail()" :disabled="isSendingMail"> &nbsp;&nbsp; <i class="fa fa-envelope-o"></i> &nbsp;&nbsp;
                  <span v-if="isSendingMail">Sending mail...</span>
                  <span v-else>Mail Invoice</span>
                </button>
              </div>
              <div class="col-6 col-xs-6 text-right pull-right">
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import global from "@/mixins/global";
export default {
  data() {
    return {
      order: null,
      isSendingMail: false
    };
  },
  mounted() {
    this.getOrder();
  },
  mixins: [global],
  computed: {
     isPaid() {
       return this.order.status.name === 'paid';
     },
     isPending() {
      return this.order.status.name === 'pending';
    }
  },
  methods: {
    getOrder() {
      this.$loading(false);
      axios.get(`orders/${this.$route.params.id}`).then((response) => {
         this.order = response.data;
         this.$loading(false);
      }).catch(() => {
         this.$loading(false);
      });
    },

    sendEmail() {
      this.isSendingMail = true;
      axios.post(`orders/${this.$route.params.id}/email`).then((response) => {
        this.$toasted.show('Order email successfully send.');
        this.isSendingMail = false;
      }).catch(() => {
        this.isSendingMail = false;
      });
    }
  }
};
</script>

<style>

/*Invoice*/
.invoice .top-left {
    color:#3ba0ff;
}

.invoice .top-right {
  text-align:right;
  padding-right:20px;
}

.invoice .table-row {
  margin-left:-15px;
  margin-right:-15px;
  margin-top:25px;
}

.invoice .payment-info {
  font-weight:500;
}

.invoice .table-row .table>thead {
  border-top:1px solid #ddd;
}

.invoice .table-row .table>thead>tr>th {
  border-bottom:none;
}

.invoice .table>tbody>tr>td {
  padding:8px 20px;
}

.invoice .invoice-total {
  margin-right:-10px;
  font-size:16px;
}

.invoice .last-row {
  border-bottom:1px solid #ddd;
}

.invoice-ribbon {
  width:105px;
  height:88px;
  overflow:hidden;
  position:absolute;
  top:-1px;
  left:14px;
}

.ribbon-inner {
  text-align:center;
  -webkit-transform:rotate(0deg);
  -moz-transform:rotate(0deg);
  -ms-transform:rotate(0deg);
  -o-transform:rotate(0deg);
  position:relative;
  padding:5px;
  left:-5px;
  top:11px;
  width:120px;
  background-color:#66c591;
  font-size:15px;
  color:#fff;
}

.ribbon-inner:before,.ribbon-inner:after {
  content:"";
  position:absolute;
}

.ribbon-inner:before {
  left:0;
}

.ribbon-inner:after {
  right:0;
}

@media(max-width:575px) {
  .invoice .top-left,.invoice .top-right,.invoice .payment-details {
    text-align:center;
  }

  .invoice .from,.invoice .to,.invoice .payment-details {
    float:none;
    width:100%;
    text-align:center;
    margin-bottom:25px;
  }

  .invoice p.lead,.invoice .from p.lead,.invoice .to p.lead,.invoice .payment-details p.lead {
    font-size:22px;
  }

  .invoice .btn {
    margin-top:10px;
  }
}

@media print {
  .invoice {
    width:900px;
    height:800px;
  }
}
</style>